import React, { Component } from 'react';
import './App.css'
import { fb } from './firebase_db'

// import MapExample from './heatmap'
import MapExample from './heatmap/testHeatmap.jsx'
import {fabric} from 'fabric';

class App extends Component {

  constructor() {
    super();
    this.state = {
      users: []
    }
  };

  onFetchData(users, response) {
    console.log(response.val());
    users = response.val()
    this.setState({ users });
  };

  onDelete(users, response) {
    let index = users.findIndex(user => user.id == response.key);
    users.splice(index, 1);
    this.setState({ users });
  };

  onUpdate(users, response) {
    console.log(response);
    console.log(users);
    let id = 1;
    let index = users.findIndex(user => user.id == id);
    // users.splice(index, 1, response.val());

    users[index][response.key] = response.val()
    this.setState({ users });
  };

  render() {
    return (
      <div className="App" >

        <div className="heatmapExample">
          <MapExample />


        </div>

      </div>
    );
  }

  componentDidMount() {
    let users = [...this.state.users];
    // let listenTo = [
    //   { event: 'child_added', cb: response => this.onInsert(users, response) }
    // ]
    // fb.listen(listenTo)
    // fb.fetchData(response => this.onFetchData(users, response));
    // fb.listenToDelete(response => this.onDelete(users, response));
    // fb.listenToUpdate(response => this.onUpdate(users, response));


  }

}

export default App;
