import React, {Component} from 'react';
import {fabric} from 'fabric';
import img from '../img/img.jpg';

export default class Rectangle extends Component {

  constructor(props) {
    super(props);

    this.state = {
      rectangle: false,
      freeShape: false,
      circle: false,
      freeMove: false,
      polygon: false
    };
  };

  drawMode = () => {
    if (this.props.moveMapStatus) {
      this.props.toggleMoveStatus()
    }

    if (this.state.freeShape) {
      this.restartState()
      this.canvas.isDrawingMode = false;
      return
    } else {
      this.setState({polygon: false, freeShape: true, circle: false, rectangle: false, freeMove: false})
      this.props.freeDraw(this.canvas, true)
    }

  }
  drawCircleShape = (data) => {
    if (this.props.moveMapStatus) {
      this.props.toggleMoveStatus()
    }
    if (this.state.circle) {
      this.restartState()
      return
    } else {
      this.setState({polygon: false, rectangle: false, circle: true, freeShape: false, freeMove: false})
      this.canvas.isDrawingMode = false;
    }
    let x,
      y,
      started;
    let canvas = this.canvas
      let isDown,
        origX,
        origY,
        square;
      canvas.clear()
      canvas.off('mouse:down').off('mouse:move').off('mouse:up').off('object:added')
      if (canvas.getActiveObject()) {
        return false;
      }

      canvas.on('mouse:down', function(o) {
        if (canvas._objects.length > 0)
          return
        isDown = true;
        var pointer = canvas.getPointer(o.e);
        origX = pointer.x;
        origY = pointer.y;
        square = new fabric.Circle({
          left: origX, top: origY, radius: 10, fill: 'rgba(255,0,0,0.5)',
          // stroke:'red',
          // strokeWidth:3,

        });

        canvas.add(square);
        square.on('mousedown', function() {
          data.checkForSelectedGaze(this.canvas, square)
        })
        square.on('mouseup', function() {
          data.checkTranslate(this.canvas, square, false)
        })
        canvas.setActiveObject(square);
      });

      canvas.on('mouse:move', function(o) {
        if (!isDown)
          return;

        var pointer = canvas.getPointer(o.e);
        var radius = Math.max(Math.abs(origY - pointer.y), Math.abs(origX - pointer.x)) / 2;
        // if (radius > square.strokeWidth) {
        //     radius -= square.strokeWidth/2;
        // }
        square.set('radius', radius);

        if (origX > pointer.x) {
          square.set({originX: 'right'});
        } else {
          square.set({originX: 'left'});
        }
        if (origY > pointer.y) {
          square.set({originY: 'bottom'});
        } else {
          square.set({originY: 'top'});
        }

        square.setCoords();
        canvas.renderAll();
      });

      canvas.on('mouse:up', function(o) {
        isDown = false;
        // canvas.off('mouse:down').off('mouse:move').off('mouse:up').off('object:added')
      });
    }

    drawRectangle = (data) => {
      if (this.props.moveMapStatus) {
        this.props.toggleMoveStatus()
      }
      if (this.state.rectangle) {
        this.restartState()
        return
      } else {
        this.setState({polygon: false, rectangle: true, circle: false, freeShape: false, freeMove: false})
        this.canvas.isDrawingMode = false;
      }

      let x,
        y,
        started;
      let canvas = this.canvas
        let isDown,
          origX,
          origY,
          rect;

        canvas.clear()
        canvas.off('mouse:down').off('mouse:move').off('mouse:up').off('object:added')

        canvas.on('mouse:down', function(o) {
          if (canvas._objects.length > 0)
            return
          isDown = true;
          var pointer = canvas.getPointer(o.e);
          origX = pointer.x;
          origY = pointer.y;
          rect = new fabric.Rect({
            left: origX,
            top: origY,
            originX: 'left',
            originY: 'top',
            width: pointer.x - origX,
            height: pointer.y - origY,
            angle: 0,
            fill: 'rgba(255,0,0,0.5)',
            transparentCorners: false
          });
          canvas.add(rect);
          rect.on('mousedown', function() {
            data.checkForSelectedGaze(this.canvas, rect)
          })
          rect.on('mouseup', function() {
            data.checkTranslate(this.canvas, rect, false)
          })

        });

        canvas.on('mouse:move', function(o) {

          if (!isDown)
            return;
          var pointer = canvas.getPointer(o.e);

          if (origX > pointer.x) {
            rect.set({
              left: Math.abs(pointer.x)
            });
          }
          if (origY > pointer.y) {
            rect.set({
              top: Math.abs(pointer.y)
            });
          }

          rect.set({
            width: Math.abs(origX - pointer.x)
          });
          rect.set({
            height: Math.abs(origY - pointer.y)
          });

          rect.setCoords();
          canvas.renderAll();
        });

        canvas.on('mouse:up', function(o) {
          isDown = false;

          // canvas.off('mouse:down').off('mouse:move').off('mouse:up').off('object:added')

        });
      }
      restartState = () => {
        this.canvas.clear()
        this.canvas.off('mouse:down').off('mouse:move').off('mouse:up').off('object:added')
        this.setState({rectangle: false, freeShape: false, circle: false, freeMove: false, polygon: false})
      }
      stopMoveState = () => {
        this.canvas.selection = false;
        this.canvas.isDrawingMode = false;
        this.canvas.clear()
        this.canvas.off('mouse:down').off('mouse:move').off('mouse:up').off('object:added')
        this.setState({
          rectangle: false,
          freeShape: false,
          circle: false,
          polygon: false,
          freeMove: !this.state.freeMove
        })
      }

      drawPolygon = (data) => {
        if (this.props.moveMapStatus) {
          this.props.toggleMoveStatus()
        }
        if (this.state.polygon) {
          this.restartState()
          this.canvas.drawPolygon = false

          return
        } else {
          this.setState({polygon: true, rectangle: false, circle: false, freeShape: false, freeMove: false})
          this.canvas.isDrawingMode = false;
        }

        let roof = null;
        let type = true;
        let roofPoints = [];
        let lines = [];
        let lineCounter = 0;
        let canvas = this.canvas;
        let self = this;
        let topPadding = null;
        let leftPadding = null;
        let origX = 0;
        let origY = 0;
        let x = null;
        let y = null;
        canvas.clear()
        canvas.off('mouse:down').off('mouse:move').off('mouse:up').off('object:added')
        function findTopPaddingForRoof(roofPoints,lineCounter) {
              var result = 999999;
              for (var f = 0; f < lineCounter; f++) {
                  if (roofPoints[f].y < result) {
                      result = roofPoints[f].y;
                  }
              }
              return Math.abs(result);
          }

        function findLeftPaddingForRoof(roofPoints,lineCounter) {
              var result = 999999;
              for (var i = 0; i < lineCounter; i++) {
                  if (roofPoints[i].x < result) {
                      result = roofPoints[i].x;
                  }
              }
              return Math.abs(result);
          }
        canvas.drawPolygon = true;
        canvas.on('mouse:down', function(options) {

          if (canvas.drawPolygon) {
            let pointer = canvas.getPointer(options.e);
            origX = pointer.x;
            origY = pointer.y;
            x = origX;
            y = origY;
            canvas.selection = false;
            if (topPadding === null) {
              topPadding = Math.abs(y)
            } else if (topPadding > y) {
              topPadding = Math.abs(y)
            }

            if (leftPadding === null) {
              leftPadding = Math.abs(x)
            } else if (leftPadding > x) {
              leftPadding = Math.abs(x)
            }
            roofPoints.push(new fabric.Point(x, y));
            let points = [x, y, x, y];
            lines.push(new fabric.Line(points, {
              strokeWidth: 3,
              selectable: false,
              stroke: 'red',
              originX: 'center',
              originY: 'center'
            }));
            canvas.add(lines[lineCounter]);
            lineCounter++;
            canvas.on('mouse:up', function(options) {
              canvas.selection = true;
            });


          }

        });
        canvas.on('mouse:move', function(options) {
          if (lines[0] !== null && lines[0] !== undefined && type) {
            let pointer = canvas.getPointer(options.e);
            x = pointer.x;
            y = pointer.y;

            lines[lineCounter - 1].set({x2: x, y2: y});

            canvas.renderAll();
          }
        });

        fabric.util.addListener(fabric.document, 'dblclick', function createObject() {
          if(lines.length < 4){
            return
          }
          canvas.drawPolygon = false
          lines.forEach(function(value, index, ar) {
            canvas.remove(value);
          });
          leftPadding = findLeftPaddingForRoof(roofPoints,lineCounter);
          topPadding = findTopPaddingForRoof(roofPoints,lineCounter);

          roofPoints.push(new fabric.Point(roofPoints[0].x, roofPoints[0].y))
          roof = new fabric.Polyline(roofPoints, {
            fill: 'rgba(255,0,0,0.5)',
            stroke: 'red',
            strokeWidth: 3,
            left: leftPadding,
            top: topPadding
          });

          canvas.add(roof);
          roof.on('mousedown', function() {
            data.checkForSelectedGaze(canvas, roof)
          })
          roof.on('mouseup', function() {
            data.checkTranslate(canvas, roof, false)
          })
          // canvas.off('mouse:down').off('mouse:move').off('mouse:up')

          // fabric.util.removeListener(fabric.document, 'dblclick',createObject)
          canvas.renderAll();

          //clear arrays
          roofPoints = [];
          lines = [];
          lineCounter = 0;

        });

      }
      render() {
        return (<div className="icons">

          <button className={` ${this.state.polygon
              ? "button-active"
              : ""}`} onClick={() => this.drawPolygon(this.props)}>
            <i className="material-icons">polymer</i>
          </button>
          <button className={` ${this.state.freeShape
              ? "button-active"
              : ""}`} onClick={this.drawMode}>
            <i className="material-icons">edit</i>
          </button>
          <button className={` ${this.state.rectangle
              ? "button-active"
              : ""}`} onClick={(e) => this.drawRectangle(this.props)}>
            <i className="material-icons dp48">format_shapes</i>
          </button>
          <button className={` ${this.state.circle
              ? "button-active"
              : ""}`} onClick={(e) => this.drawCircleShape(this.props)}>
            <i className="material-icons dp48">panorama_fish_eye</i>
          </button>
          <button className={` ${this.state.freeMove
              ? "button-active"
              : ""}`} onClick={() => {
              this.props.toggleMoveStatus();
              this.stopMoveState()
            }}>
            <i className=" material-icons ">open_with</i>
          </button>

        </div>)
      };

      componentDidMount() {
        this.canvas = new fabric.Canvas('icons-canvas', {isDrawingMode: false});
        // this.canvas.setBackgroundImage(img, this.canvas.renderAll.bind(this.canvas), {
        //   // Needed to position backgroundImage at 0/0
        //   // originX: 'left',
        //   // originY: 'top',
        //   // width: 200,
        //   // height: 80,
        //   // size: 'cover'
        //   // opacity: 0.5,
        //
        //   left: 10,
        //   top: 10,
        //   originX: 'left',
        //   originY: 'top',
        //   crossOrigin: 'anonymous'
        //
        //   });
  //         this.canvas.setOverlayImage(img, this.canvas.renderAll.bind(this.canvas), {
  // // Needed to position overlayImage at 0/0
  //         originX: 'left',
  //         originY: 'top'
  //           });
      };


    }
