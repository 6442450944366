import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { fb } from './firebase_db';

fb.init();
const email = 'test@test.com';
const pass = 'test123'
fb.login(email,pass);
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
