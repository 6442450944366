import firebase from 'firebase';
import { config } from './config.js';

export const fb = {
  database: null,

  init() {
    firebase.initializeApp(config);
    this.database = firebase.database();
  },

  login(email, password) {
    return firebase.auth().signInWithEmailAndPassword(email, password)
      .then(response => {
        return Promise.resolve(true);
      })
      .catch(err => Promise.reject(false))
  },
  // listen(listenTo) {
  //   listenTo.forEach(el => {
  //     this.database.ref('users').on(el.event, function (response) {
  //       el.cb(response);
  //     });
  //   })
  // },

  fetchData(cb) {
    this.database.ref('users').orderByKey().on('value', function (response) {
      cb(response);
    });
  },

  listenToDelete(cb) {
    this.database.ref('users').on('child_removed', function (response) {
      cb(response);
    });
  },

  listenToUpdate(cb) {
    this.database.ref('users/1').on('child_changed', function (response) {
      cb(response);
    });
  },
}
