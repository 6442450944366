export const config = {
  apiKey: "AIzaSyB-gbKe-dC1LpKV2XS0-U-A9sQbph79-74",
  authDomain: "gazeviewer.firebaseapp.com",
  databaseURL: "https://gazeviewer.firebaseio.com",
  projectId: "gazeviewer",
  storageBucket: "gazeviewer.appspot.com",
  messagingSenderId: "946137157170",
  appId: "1:946137157170:web:7f3e1596b1ba165e2f362b"
};

