import React, {Component} from 'react';
import ReactDOM from "react-dom";
import h337 from "heatmap.js";
import img from '../img/img.jpg'
// import "./styles.css";
import {fabric} from 'fabric';

import Rectangle from '../canvas/canvasShapes.jsx'

export default class MapExample extends Component {

  constructor(props) {
    super(props);

    this.state = {
      data: this.dataForHeatMap(),
      selectedGaze: [],
      recData: {
        startMatrix: null,
        move: false
      },
      moveMap: false,
      cursorStartPosition: {
        x: null,
        y: null
      },
      gazeMoved: false,
      zoomIn:false,
      zoomOut:false,
      scale:1,

    };
  };

  heatmapInstance = null;

  dataForHeatMap = (w=840, h=540) => {
    let points = [];
    let max = 0;
    let width = w;
    let height = h;
    let len = 100;

    while (len--) {
      var val = Math.floor(Math.random() * 100);
      max = Math.max(max, val);
      var point = {
        x: Math.floor(Math.random() * width),
        y: Math.floor(Math.random() * height),
        value: val,

      };
      // let point = {
      //   x:150,
      //   y:150,
      //   value:100
      // }
      points.push(point);
    }
    // heatmap data format
    let data = {
      max: max,
      data: points
    };

    return data;
  }

  drawHeatMap = (data,radius=50) => {

    this.heatmapInstance = h337.create({container: document.querySelector('.heatmap'),
      radius:radius,
  // onExtremaChange will be called whenever there's a new maximum or minimum
    onExtremaChange: function(data) {
    // console.log('heatmap');
    // console.log(data);


  }
});
    this.heatmapInstance.setData(data);

  }

  getStartingMatrix = (rect,selectedGaze) => {

    if (!this.state.recData.move) {
      let recData = {
        ...this.state.recData
      }
      recData.startMatrix = rect.calcOwnMatrix()
      recData.move = true;
      this.setState({recData,selectedGaze})
    }

  }

  checkTranslate = (canvas, obj,freeDraw) => {

    if (this.state.selectedGaze.length === 0) {
      return
    }

    let newMatrix = obj.calcOwnMatrix();

    let translateX = (newMatrix[4] - this.state.recData.startMatrix[4]).toFixed(0)
    let translateY = (newMatrix[5] - this.state.recData.startMatrix[5]).toFixed(0)


    if (translateX != 0 || translateY != 0) {

      let newData = {
        ...this.state.data
      };
      let selectedGaze = this.state.selectedGaze;
      selectedGaze.map(function(gaze, i) {
        newData.data[selectedGaze[i]].x = newData.data[selectedGaze[i]].x + parseFloat(translateX);
        newData.data[selectedGaze[i]].y = newData.data[selectedGaze[i]].y + parseFloat(translateY);
      })

      // this.clearCanvase()
      // this.drawHeatMap(newData)

      this.heatmapInstance.setData(newData);
      let recData = {
        startMatrix: null,
        move: false
      }

      this.setState({data: newData, recData, selectedGaze: [], gazeMoved: true})
      canvas.remove(obj)
      if(freeDraw){
        this.freeDraw(canvas,freeDraw)
      }
      canvas.selection = false;
      canvas.drawPolygon = true;
    }

  }
  clearCanvase = () => {
    var canvas = document.querySelector('.heatmap-canvas');
    canvas.remove()
  }

  checkForSelectedGaze = (canvas, obj) => {
    let selected = []
    this.state.data.data.map(function(point, i) {
      let gaze = {
        x: point.x,
        y: point.y
      }

      if (!canvas.isTargetTransparent(obj, point.x, point.y)) {
        selected.push(i)
      }
    })
    if (selected.length > 0) {

      let selectedGaze = [...selected]
      this.getStartingMatrix(obj,selectedGaze)
      // this.setState({selectedGaze})
      console.log(selected);
    }

  }
  setCursorPositionStart = (x, y) => {
    let counter = 0;
    let cursorX = x / this.state.scale;
    let cursorY = y / this.state.scale;
    this.state.data.data.map(function(point) {
      if (point.x - 15 < cursorX && point.x + 15 > cursorX && point.y - 15 < cursorY && point.y + 15 > cursorY) {
        counter++
      }
    })
    if (counter != 0) {
      const elm = document.querySelector('.heatmap');
      elm.classList.add('moveMap-grabbing')
      let cursorStartPosition = {
        x: x,
        y: y
      }
      this.setState({cursorStartPosition})

    } else {
      const elm = document.querySelector('.heatmap');
      elm.classList.remove('moveMap-grabbing')
      let cursorStartPosition = {
        x: null,
        y: null
      }
      this.setState({cursorStartPosition})

    }
  }
  setCursorPositionEnd = (x, y) => {
    if (this.state.cursorStartPosition.x != null) {
      let offsetX = ((this.state.cursorStartPosition.x - x) / this.state.scale).toFixed(0);
      let offsetY = ((this.state.cursorStartPosition.y - y) / this.state.scale).toFixed(0);
      let newData = JSON.parse(JSON.stringify(this.state.data))
      newData.data.map(function(point) {
        point.x = point.x - parseFloat(offsetX);
        point.y = point.y - parseFloat(offsetY);
      })
      // this.clearCanvase()
      this.setState({newData})
      // this.drawHeatMap(newData)
      this.heatmapInstance.setData(newData)
    }

  }
  getMousePos(canvas, evt) {
    var rect = canvas.getBoundingClientRect();
    return {
      x: evt.clientX - rect.left,
      y: evt.clientY - rect.top
    };
  }

  handleMouseDown = (evt) => {

    let canvas = document.querySelector('.upper-canvas');
    var mousePos = this.getMousePos(canvas, evt);

    this.setCursorPositionStart(mousePos.x, mousePos.y)
    canvas.addEventListener("mousemove", this.handleMouseMove, true);

  }
  handleMouseMove = (evt) => {

    let canvas = document.querySelector('.upper-canvas');
    let mousePos = this.getMousePos(canvas, evt);
    this.setCursorPositionEnd(mousePos.x, mousePos.y)

  }
  handleMouseUp = (evt) => {
    const elm = document.querySelector('.heatmap');
    elm.classList.remove('moveMap-grabbing')
    let canvas = document.querySelector('.upper-canvas');
    canvas.removeEventListener("mousemove", this.handleMouseMove, true);

    let newStateData = this.state.newData;
    console.log(newStateData);
    this.setState({data:newStateData})
    //api for setting new coordinates in DB
  }

  toggleMoveStatus = () => {
    let canvas = document.querySelector('.upper-canvas');
    if (!this.state.moveMap) {

      canvas.addEventListener("mousedown", this.handleMouseDown, true);
      canvas.addEventListener("mouseup", this.handleMouseUp, true);
    } else {
      canvas.removeEventListener("mousedown", this.handleMouseDown, true);
      canvas.removeEventListener("mouseup", this.handleMouseMove, true);
    }
    this.setState({
      moveMap: !this.state.moveMap
    })
  }
  clearGazeMoved = () => {
    this.setGazeMoved(false)
  }

  freeDraw = (canvas,freeShape) => {
    let t = this
    canvas.isDrawingMode = !canvas.isDrawingMode;
    canvas.clear()
    canvas.off('mouse:down').off('mouse:move').off('mouse:up').off('object:added')
    canvas.on('object:added', (e) => {
      if(freeShape){
        e.target.canvas._objects.map(obj => {
          obj.on('mousedown', function() {
           t.checkForSelectedGaze(canvas, obj)
          })
          obj.on('mouseup', function() {
            t.checkTranslate(this.canvas, obj,true)
          })
        })
        // this.setState({freeShape:false})
      }

      e.target.canvas.isDrawingMode = false;
      e.target.fill = "red"
      e.target.opacity = 0.3;

    })


    canvas.renderAll()
  }

  zoomIn = () => {
    const img = document.querySelector('.heatmap');
    const can = document.querySelector(".canvas-container")
    let scale = parseFloat(this.state.scale + 0.2);
    img.style.transform = 'scale('+scale+')'
    can.style.transform = 'scale('+scale+')'
    this.setState({scale})

      }

  zoomOut = () => {
    const img = document.querySelector('.heatmap ');
    const can = document.querySelector(".canvas-container")
    let scale = parseFloat(this.state.scale - 0.2);
    can.style.transform = 'scale('+scale+')'
    img.style.transform = 'scale('+scale+')'
    this.setState({scale})
  }

  changeSettings = (e) => {
    let name = e.target.name
    let value = parseFloat(e.target.value)
    if(name === 'maxOpacity'){
      value = value/100
    }
    let config = {...this.state.config}

    this.setState({config})
     config = {
      ...config,
      [name]:value
    };
    this.setState({config})
    this.heatmapInstance.configure(config);

  }
  changeRadius = (e) => {
    let radius = parseFloat(e.target.value)
    this.clearCanvase()
    this.drawHeatMap(this.state.data,radius)
      }
  render() {

    return (
      <div>
      <div className={`heatmap ${this.state.moveMap ? "moveMap-active" : ""}`}>
      <img style={{
          width: "840px",
          height: "540px"
        }} src={img}/>

      </div>
      <div className="options-wrapper">
        <label>Opacity
        <input name="maxOpacity" onChange={(e) => this.changeSettings(e)} type="range" min="1" max="100" value={this.state.config ? this.state.config.maxOpacity * 100 : 100} className="blur test" />
      </label>
      <label>Kernel Size
      <input name="radius" onChange={(e) => this.changeRadius(e)} type="range" min="1" max="100" value={this.state.config ? this.state.config.radius : 50} className="blur test" />
      </label>
      <button className={`test ${this.state.zoomIn ? "button-active" : ""}`} onClick={this.zoomIn} ><i className="material-icons">zoom_in</i></button>
        <button className={` ${this.state.zoomOut ? "button-active" : ""}`} onClick={this.zoomOut} ><i className="material-icons">zoom_out</i></button>
        <Rectangle freeDraw={this.freeDraw} checkForSelectedGaze={this.checkForSelectedGaze} clearGazeMoved={this.clearGazeMoved} gazeMoved={this.state.gazeMoved} checkTranslate={this.checkTranslate} moveMapStatus={this.state.moveMap} toggleMoveStatus={this.toggleMoveStatus}/>
      </div>
      <canvas id="icons-canvas" width={840} height={540}></canvas>
  </div>
    );

  }

  componentDidMount() {

    this.drawHeatMap(this.state.data)
    var config = {
      maxOpacity: 1,
      minOpacity: 0,
    };
    this.setState({config})
  }
}
